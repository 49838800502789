import { useState, useEffect, useMemo, useCallback } from "react";
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import { AgreementType } from "../types";
import { ColDef, ValueFormatterParams } from "ag-grid-community";
import { Box } from "@chakra-ui/react";
import { useApi } from "../hooks/useApi";

type AgreementsProps = {
    supplyId: string;
}

function Agreements({supplyId} : AgreementsProps) {
    const [agreementsData, setAgreementsData] = useState<AgreementType[] | undefined>(undefined);
    const {ready, getAgreements} = useApi();

    const formatDate = (dateString: string) : string => {
        if (!dateString) return "";
        const parts = dateString.split("-");
        return `${parts[2]}/${parts[1]}/${parts[0]}`;
    };
    
    const formatData = useCallback((agreements : AgreementType[]): AgreementType[] => {
        return agreements.map((agreement: AgreementType) => {
            return {
                ...agreement,
                eb_contracttype: agreement.eb_contracttype ? "Out of Contract" : "Fixed Term",
                live_on: formatDate(agreement.live_on),
                expires_on: formatDate(agreement.expires_on),
                eb_ismop: agreement.eb_ismop ? "MOP" : "Utility"
            };
        });
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const responseJson = await getAgreements(supplyId);
            const formattedData = formatData(responseJson);
            setAgreementsData(formattedData);
        }

        if (ready) {
            fetchData()
        }
            
    }, [formatData, getAgreements, ready, supplyId]);

    function penceFormatter(params: ValueFormatterParams) {
        if (params.value == null) return "";
        return `${params.value}p`;
    }

    function poundFormatter(params: ValueFormatterParams) {
        if (params.value == null) return "";
        return `£${params.value}`;
    }
    
    // Column Definitions: Defines the columns to be displayed.
    const [colDefs] = useState<ColDef<AgreementType>[]>([
        { field: "eb_utility", headerName: "Utility", hide: true},
        { field: "eb_contracttype", headerName: "Contract Type", minWidth: 125},
        { field: "live_on", headerName: "Start Date", minWidth: 135},
        { field: "expires_on", headerName: "End Date", sort: "desc", sortIndex: 1, minWidth: 135},
        { field: "eb_supplier_name", headerName: "Supplier", minWidth: 125},
        { field: "eb_standingcharge", headerName: "Std. C (p/day)", valueFormatter: penceFormatter, minWidth: 125},
        { field: "eb_primaryrate", headerName: "Rate 1 (p/kWh)", valueFormatter: penceFormatter, minWidth: 125},
        { field: "eb_secondaryrate", headerName: "Rate 2 (p/kWh)", valueFormatter: penceFormatter, minWidth: 125},
        { field: "eb_tertiaryrate", headerName: "Rate 3 (p/kWh)", hide: true, valueFormatter: penceFormatter, minWidth: 125},
        { field: "eb_fitrate", headerName: "FiT Rate (p/kWh)", hide: true, valueFormatter: penceFormatter, minWidth: 125},
        { field: "eb_capacitychargerate", headerName: "Capacity (kVA p/Month)", valueFormatter: poundFormatter, minWidth: 125},
        { field: "eb_ismop", headerName: "Type", rowGroup: true, hide: true},
    ]);

    const defaultColDef: ColDef = useMemo(
        () => ({
          filter: true,
          enableRowGroup: true,
          enableValue: true,
        }),
        []
      );
    
    return (
        <Box w="100%">
          <div
          className="ag-theme-quartz" // applying the Data Grid theme
          style={{ height: '800px', width: '100%' }} // the Data Grid will fill the size of the parent container
          >
            <AgGridReact<AgreementType>
                rowData={agreementsData}
                columnDefs={colDefs}
                groupDisplayType={'groupRows'}
                rowGroupPanelShow={"always"}
                defaultColDef={defaultColDef}
                autoSizeStrategy={{type: 'fitGridWidth'}}
                groupDefaultExpanded={1}
            />
          </div>
        </Box>
    )
}
  
export default Agreements;
