import { useState, useEffect, useContext } from "react"
import { NotificationContextType, SupplyType } from "../types";
import HHGraph from "../components/HHGraph";
import MeterReadSubmission from "../components/MeterReadSubmission";
import Agreements from "./Agreements";
import { Link } from "wouter";
import { Box, Divider, Grid, GridItem, Icon, Spinner, Tab, Table, TableContainer, TabList, TabPanel, TabPanels, Tabs, Th, Thead, Tr, useDisclosure, VStack, Text } from "@chakra-ui/react";
import { useApi } from "../hooks/useApi";
import { NotificationContext } from "../components/NotificationPane";
import SupplyIcon from "../components/SupplyIcon";
import { FaTicketAlt } from "react-icons/fa";
import { getCEDString } from "../components/CellRenderers";
import TicketsDrawer from "../components/TicketsDrawer";
import SupplyField from "../components/SupplyField";
import CEDLink from "../util/CEDLink";

export type hhMonthlyDataType = {
  supply_number : string;
  month : string;
  month_total : number;
}

type SupplyProps = {
  id: string
}

function Supply({id}: SupplyProps) {
  const { isOpen: isTicketsOpen, onOpen: onTicketsOpen, onClose: onTicketsClose } = useDisclosure();
  const { ready, getSupply, getSupplyHHData } = useApi();
  const { pushNotification } = useContext<NotificationContextType | null>(NotificationContext)!;
  const [supplyData, setSupplyData] = useState<SupplyType | undefined>(undefined);
  const [hhData, setHhData] = useState<hhMonthlyDataType[] | undefined>(undefined);

  useEffect(() => {
    async function loadSupply() {
      try {
        const response = await getSupply(id);
        setSupplyData(response);
      } catch(err) {
        pushNotification("Could not load supply.", "error")
      }
    }

    if (ready && id)
      loadSupply();
  }, [ready, id]);

  useEffect(() => {
    async function loadHHData(supplyNumber: string) {
      try {
        const response = await getSupplyHHData(supplyNumber);
        setHhData(response);
      } catch(err) {
        pushNotification("Could not load chart data.", "error")
      }
    }

    if (ready && supplyData?.supply_number) 
      loadHHData(supplyData.supply_number);

  }, [ready, supplyData]);

  // checks if num exists and converts to comma'd format
  function numFormat(num: Number){
    if (num == null) return '';
    return num.toLocaleString();
  }


  if (supplyData === undefined) return <Spinner />

  // !!! store these in seperate files?
  // --------- lists supply data fields to show under which supply types and whether to show if null ---------
  const supplyFieldData: {name: string, valueString: string, valueRaw: string | number | Date | boolean | null}[] = [
    {"name": "Account", "valueString": `${supplyData.account_name}`, "valueRaw": supplyData.account_name},
    {"name": "Site", "valueString": `${supplyData.site_address}`, "valueRaw": supplyData.site_address},
    {"name": "Contract End Date", "valueString": `${getCEDString(((supplyData.contract_end_date) ? new Date(supplyData.contract_end_date) : null), supplyData.rollingcontract)}`, "valueRaw": supplyData.contract_end_date},
    {"name": "Current Consumption", "valueString": `${numFormat(supplyData.eb_currentconsumption)}`, "valueRaw": supplyData.eb_currentconsumption},
    {"name": "Supply Type", "valueString": `${supplyData.supply_type}`, "valueRaw": supplyData.supply_type},
    {"name": "Supply Sub-Type", "valueString": `${supplyData.supply_subtype}`, "valueRaw": supplyData.supply_subtype},
    {"name": "Current Supplier", "valueString": `${supplyData.supply_supplier}`, "valueRaw": supplyData.supply_supplier},
  ];

  if (supplyData.supply_type === "Electricity" || supplyData.supply_type === "Gas"){
    supplyFieldData.push(
      {"name": "Meter Serial Number", "valueString": `${supplyData.eb_meterserialnumber}`, "valueRaw": supplyData.eb_meterserialnumber},
      {"name": "AMR?", "valueString": `${supplyData.eb_amrchecked}`, "valueRaw": supplyData.eb_amrchecked},
      {"name": "Date of Meter Install", "valueString": `${new Date(supplyData.eb_dateofmeterinstall).toLocaleDateString()}`, "valueRaw": supplyData.eb_dateofmeterinstall},
      {"name": "CCL Payable", "valueString": `${supplyData.eb_CCLPayable}%`, "valueRaw": supplyData.eb_CCLPayable},
      {"name": "EII Discount", "valueString": `${supplyData.eb_eiidiscount}%`, "valueRaw": supplyData.eb_eiidiscount},
    )
  }

  if (supplyData.supply_subtype === "HH"){
    supplyFieldData.push(
      {"name": "Allocated Capacity (kVA)", "valueString": `${supplyData.eb_AllocatedCapacitykVA}`, "valueRaw": supplyData.eb_AllocatedCapacitykVA},
      {"name": "Voltage", "valueString": `${supplyData.eb_Voltage}`, "valueRaw": supplyData.eb_Voltage},
      {"name": "Meter Category", "valueString": `${supplyData.eb_MeterCategory}`, "valueRaw": supplyData.eb_MeterCategory},
    )
  }

  if (supplyData.supply_type === "Water"){
    supplyFieldData.push(
      {"name": "Live Rateable Value", "valueString": `£${numFormat(supplyData.eb_LiveRateableValue)}`, "valueRaw": supplyData.eb_LiveRateableValue},
      {"name": "Return to Sewer Allowance", "valueString": `${supplyData.eb_ReturntoSewerAllowance}%`, "valueRaw": supplyData.eb_ReturntoSewerAllowance},
      {"name": "Property Drainage Charges Applicable?", "valueString": `${supplyData.eb_PropertyDrainageChargesApplicable}`, "valueRaw": supplyData.eb_PropertyDrainageChargesApplicable},
      {"name": "Meter Location (Water)", "valueString": `${supplyData.eb_meterlocation}`, "valueRaw": supplyData.eb_meterlocation},
    )
  }
  // ---------------------------------------------------------------------------------------------------



  return (
    <>
      <TicketsDrawer onClose={onTicketsClose} isOpen={isTicketsOpen} supplyid={supplyData.supply_id}></TicketsDrawer>
      <Tabs backgroundColor={"white"} isLazy align='start' variant='enclosed' colorScheme='purple'>
        <TabList>
          <Tab>Details</Tab>
          <Tab>Contracts</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <VStack margin={4}>
              <Box w="100%">
                <TableContainer>
                  <Table variant='simple'>
                    <Thead>
                      <Tr>
                        <Th colSpan={2} fontSize={18}><SupplyIcon utility={supplyData.supply_type}/>{supplyData.supply_verbose}</Th>
                      </Tr>
                      <Tr>
                        <Th fontSize={14}><MeterReadSubmission supplyData={supplyData} /></Th>
                        <Th fontSize={14}><Link color='teal.500' href='#' onClick={onTicketsOpen}><Icon as={FaTicketAlt}/> Ticket</Link></Th>
                      </Tr>
                    </Thead>
                  </Table>
                </TableContainer>
              </Box>
              <Box w="100%">
                <Grid templateColumns='repeat(2, 1fr)' gap={2}>
                {supplyFieldData.map((supplyField) => (
                  supplyField.name === 'Contract End Date' ? (
                    //!!! this is a bit of a hack but couldn't think of another way of adding a jsx element without changing the methodology
                    //!!! come back and reformat to accept jsx elements into supplyfield component
                    <Box m={2}>
                      <Text as='b' fontSize={14}>{supplyField.name}</Text><br/>
                      <CEDLink contract_end_date={supplyData.contract_end_date} rollingcontract={supplyData.rollingcontract}/>
                      <Divider></Divider>
                    </Box>
                  ) : (
                    <GridItem>
                      <SupplyField 
                        name={supplyField.name} 
                        value={supplyField.valueRaw === null ? "-" : supplyField.valueString} 
                      />
                    </GridItem>
                  )
                ))}
                </Grid>
              </Box>
              <Box w="100%" h={250} m={4}>
                <HHGraph hhData={hhData} />
              </Box>
            </VStack>
          </TabPanel>
          <TabPanel>
            <Agreements supplyId={id} />
          </TabPanel>
        </TabPanels>
      </Tabs> 
    </>
  )
}

export default Supply
