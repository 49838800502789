import { Box, Divider, Text } from "@chakra-ui/react";

type SupplyFieldProps = {
  name: string,
  value: string
}

function SupplyField({name, value}: SupplyFieldProps) {
  if (value=="true") value = "Yes"; // convert boolean text for UX
  if (value=="false") value = "No"; // convert boolean text for UX
  return (
    <Box m={2}>
      <Text as='b' fontSize={14}>{name}</Text>
      <Text>{value}</Text>
      <Divider></Divider>
    </Box>
  )
}

export default SupplyField
