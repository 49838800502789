import { Icon, Link, useDisclosure } from "@chakra-ui/react";
import moment from "moment";
import { FaRegCalendar } from "react-icons/fa6";
import RenewalsDrawer from "../components/RenewalsDrawer";

function getCEDString(ced: Date | null, rolling: boolean) {
    let dateString;
    if (rolling){
      dateString = "Out of Contract";
    } else {
      dateString = (ced) ? moment(ced).format('DD/MM/YYYY') : "Unknown";
    }
    return dateString;
}

export default function CEDLink({contract_end_date, rollingcontract}: {contract_end_date: string | Date, rollingcontract: boolean}) {
    const { isOpen, onOpen, onClose } = useDisclosure(); // tracks status of renewals drawer
  
    const date = contract_end_date ? new Date(contract_end_date) : null;
    let dateString = getCEDString(date, rollingcontract);
  
    return (
      <>
        <RenewalsDrawer isOpen={isOpen} onClose={onClose} ced={date} />
        <Link color='blue.100' onClick={onOpen}><Icon as={FaRegCalendar}/> {(dateString)}</Link>
      </>
    )
}