import { ColDef } from "ag-grid-enterprise";
import { useContext, useEffect, useMemo, useState } from "react";
import { NotificationContextType, TicketType } from "../../types";
import { Box } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { NotificationContext } from "../../components/NotificationPane";
import { useApi } from "../../hooks/useApi";
import { SupplyLinkRenderer } from "../../components/CellRenderers";
import { caseInsensitiveComparator, dateValueFormatter, timezoneIndependantDateComparator } from "../../util/AgGridFunctions";

function TicketsTable() {
  const { pushNotification } = useContext<NotificationContextType | null>(NotificationContext)!;
  const {ready, getTickets} = useApi();
  const [ticketsData, setTicketsData] = useState<TicketType[] | undefined>(undefined);

  useEffect(() => {
    if (ready)
      loadTickets();
  }, [ready, getTickets]);

  async function loadTickets() {
    try {
      const responseJson = await getTickets();
      console.log("responseJson", responseJson)
      setTicketsData(await responseJson);
    } catch {
      pushNotification("Could not load tickets.", "error")
    }
  }

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs] = useState<ColDef<TicketType>[]>([
    { field: "created_date", headerName: "Created On", sort: "desc", sortIndex: 1, cellDataType: "dateString", filter: "agDateColumnFilter", filterParams: { comparator: timezoneIndependantDateComparator }, valueFormatter: dateValueFormatter},
    { field: "submitted_by", headerName: "Submitted By" },
    { field: "status", headerName: "Status" , rowGroup: true, sort: "desc", sortIndex: 0 },
    { field: "subcategory", headerName: "Category" },
    { field: "subject", headerName: "Subject" },
    { field: "description", headerName: "Description", hide: true },
    { field: "account_name", headerName: "Account" },
    { field: "site_name", headerName: "Site", hide: true },
    { field: "supply_name", headerName: "Supply", cellRenderer: SupplyLinkRenderer, minWidth: 300, cellStyle: {overflow: "visible"} },
    { field: "supplier_name", headerName: "Supplier", hide: true },
    { field: "dateofread", headerName: "Date of Read", hide: true },
  ]);

  const defaultColDef: ColDef = useMemo(() => ({
    filter: true,
    enableRowGroup: true,
    enableValue: true,
    minWidth: 150,
    comparator: caseInsensitiveComparator,
  }), []);

  const gridOptions = {
    isGroupOpenByDefault: (params: { field: string; key: string; }) => {
      return (params.field == 'status' && params.key !== 'Complete');
    },
  }

  return (
    <Box w="100%" h="100%">
      <div className="ag-theme-quartz" style={{ height: '100%', width: '100%' }}>
        <AgGridReact<TicketType>
            rowData={ticketsData}
            columnDefs={colDefs}
            groupDisplayType={'groupRows'}
            rowGroupPanelShow={"always"}
            defaultColDef={defaultColDef}
            autoSizeStrategy={{type: 'fitGridWidth'}}
            gridOptions={gridOptions}
            //domLayout={"autoHeight"} // !!! this can cause performance issues for large dataset - remove when we have finalised layout and can set fixed height
        />
      </div>
    </Box>
  )
}

export default TicketsTable