import { Text, Link, Box, Center, Flex, Icon, Popover, PopoverBody, PopoverContent, PopoverHeader, PopoverTrigger, Portal, Spacer, Spinner, Table, TableContainer, Tbody, Td, Tfoot, Th, Tr, useBoolean, useDisclosure, Tooltip } from "@chakra-ui/react";
import { NotificationContextType, SupplyType } from "../types";
import SupplyIcon from "./SupplyIcon";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import { FaTicketAlt } from "react-icons/fa";
import { useApi } from "../hooks/useApi";
import { useContext, useEffect, useState } from "react";
import { NotificationContext } from "./NotificationPane";
import TicketsDrawer from "./TicketsDrawer";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import CEDLink from "../util/CEDLink";

type SupplyModalProps = {
  supplyId: string
  supplyName: string
  onOpen: () => void
}

function SupplyPopover({supplyId, supplyName, onOpen}: SupplyModalProps) {
  const { isOpen: isTicketsOpen, onOpen: onTicketsOpen, onClose: onTicketsClose } = useDisclosure();
  const { ready, getSupply } = useApi();
  const { pushNotification } = useContext<NotificationContextType | null>(NotificationContext)!;
  const [supplyData, setSupplyData] = useState<SupplyType | undefined>(undefined);

  const [opened, setOpened] = useBoolean();

  useEffect(() => {
    async function loadSupply() {
      try {
        const response = await getSupply(supplyId);
        setSupplyData(response);
      } catch(err) {
        console.log(err)
        pushNotification("Could not load supply.", "error")
      }
    }
    if (ready && supplyId && opened)
      loadSupply();
  }, [ready, opened]);

  // need to grab the utility type from name as not all tables have utility before popover rendered
  let supplyNameUtility = "";
  if (supplyName){
    if (supplyName.includes("MPAN")) supplyNameUtility = "Electricity";
    else if (supplyName.includes("MPRN")) supplyNameUtility = "Gas";
    else if (supplyName.includes("Water")) supplyNameUtility = "Water";
    else supplyNameUtility = "?"
  }

  // shown on the initial open of modal and "onOpen" triggers the "loadSupply" call
  if (supplyData === undefined) return (
    <Popover placement='top-start' trigger="hover" onOpen={setOpened.on}>
      <PopoverTrigger>
        <Link href='#' color='blue.100' onClick={onOpen}><SupplyIcon utility={supplyNameUtility} /> {supplyName}</Link>
      </PopoverTrigger>
      <Portal>
        <PopoverContent w={350}>
          <PopoverBody>
            <Center><Spinner /></Center>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )

  return (
    <>
      <TicketsDrawer onClose={onTicketsClose} isOpen={isTicketsOpen} supplyid={supplyData.supply_id}></TicketsDrawer>
      <Popover placement='top-start' trigger="hover">
        <PopoverTrigger>
          <Link href='#' color='blue.100' onClick={onOpen}><SupplyIcon utility={supplyData.supply_type} /> {supplyData.supply_verbose}</Link>
        </PopoverTrigger>
        <Portal>
          <PopoverContent w={350}>
            <PopoverHeader fontWeight='semibold'>
              <Flex>
                <Box><SupplyIcon utility={supplyData.supply_type} /></Box>
                <Spacer />
                <Box>{supplyData.supply_verbose}</Box>
                <Spacer />
                <Box>
                  <Link color='blue.100' href='#' onClick={onOpen}><Icon as={FaArrowUpRightFromSquare}/></Link>
                </Box>
              </Flex>
            </PopoverHeader>
            <PopoverBody>
              <TableContainer>
                <Table size='sm'>
                  <Tbody>
                    <Tr>
                      <Td colSpan={2}><Tooltip label={supplyData.site_address || "-"}><Text w={275} isTruncated>{supplyData.site_address || "-"}</Text></Tooltip></Td>
                    </Tr>
                    <Tr>
                      <Td>{supplyData.supply_supplier || "-"} {supplyData.supply_supplier && <Tooltip label='Current supplier' placement='right'><InfoOutlineIcon /></Tooltip>}</Td>
                      <Td>
                        <CEDLink contract_end_date={supplyData.contract_end_date} rollingcontract={supplyData.rollingcontract} /> <Tooltip label='Contract end date' placement='right'><InfoOutlineIcon /></Tooltip>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>{supplyData.eb_meterserialnumber || "-"} {supplyData.eb_meterserialnumber && <Tooltip label='Meter serial number' placement='right'><InfoOutlineIcon /></Tooltip>}</Td>
                      <Td>{supplyData.eb_currentconsumption && supplyData.eb_currentconsumption.toLocaleString() || "-"} {supplyData.eb_currentconsumption && <Tooltip label='Current consumption' placement='right'><InfoOutlineIcon /></Tooltip>}</Td>
                    </Tr>
                  </Tbody>
                  <Tfoot>
                    <Tr>
                      <Th><Link color='teal.500' href='#' onClick={onTicketsOpen}><Icon as={FaTicketAlt}/> Ticket</Link></Th>
                    </Tr>
                  </Tfoot>  
                </Table>
              </TableContainer>
            </PopoverBody>
          </PopoverContent>
        </Portal>

      </Popover>
    </>
  )
}

export default SupplyPopover