import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import BaseTemplate from "../components/BaseTemplate";
import { MarketDataType, NotificationContextType } from "../types";
import PersistentGrid from "../components/PersistentGrid/PersistentGrid";
import { useContext, useEffect, useMemo, useState } from "react";
import { useApi } from "../hooks/useApi";
import { ColDef } from "ag-grid-enterprise";
import { PersistentGridProvider } from "../contexts/PersistentGridContext";
import { NotificationContext } from "../components/NotificationPane";
import { caseInsensitiveComparator, dateValueFormatter, timezoneIndependantDateComparator } from "../util/AgGridFunctions";

export default function MarketData() {
    const { ready, getElectricityMarketData, getGasMarketData } = useApi();
    const [loading, setLoading ] = useState<boolean>(true);
    const [gasData, setGasData] = useState<MarketDataType[]>([]);
    const [elecData, setElecData] = useState<MarketDataType[]>([]);
    const { pushNotification } = useContext<NotificationContextType | null>(NotificationContext)!;

    useEffect(() => {
        async function loadData() {
            try {
                setGasData(await getGasMarketData());
                setElecData(await getElectricityMarketData());
            } catch (err) {
                pushNotification("Could not load market data.", "error");
            }
            
            setLoading(false);
        }

        if (ready)
            loadData();
    }, [ready]);

    const [colDefs] = useState<ColDef<MarketDataType>[]>([ 
        { field: "instrumentName", headerName: "instrument Name" },
        { field: "SequenceItemName", headerName: "Sequence Item Name" },
        { field: "SequenceName", headerName: "Sequence Name" },
        { field: "Price_Date", headerName: "Price Date", cellDataType: "dateString", filter: 'agDateColumnFilter', filterParams: { comparator: timezoneIndependantDateComparator }, valueFormatter: dateValueFormatter },
        { field: "Bid", headerName: "Bid" },
        { field: "Offer", headerName: "Offer" },
        { field: "Change", headerName: "Change", cellStyle: params => {
            if (params.value < 0) {
                return { backgroundColor: '#cce6c0'};
            } else if (params.value > 0) {
                return { backgroundColor: '#FFADB0'};
            }
            return null;
        } },
    ]);

    const defaultColDef: ColDef = useMemo(() => ({
        filter: 'agMultiColumnFilter',
        enableRowGroup: true,
        enableValue: true,
        comparator: caseInsensitiveComparator,
    }), []);


    return (
        <PersistentGridProvider gridKey={"market_data"}>
            <BaseTemplate fitToWindow={true}>
                <BaseTemplate.Body>
                    <Tabs colorScheme='purple' backgroundColor={"white"} isLazy align='start' variant='enclosed' h={"100%"}>
                        <TabList>
                        <Tab>Electricity</Tab>
                        <Tab>Gas</Tab>
                        </TabList>
                        <TabPanels h={"90%"}>
                            <TabPanel h={"100%"}>
                                <Box w="100%" h="100%">
                                    <div
                                    className="ag-theme-quartz" // applying the Data Grid theme
                                    style={{ height: '100%', width: '100%' }} // the Data Grid will fill the size of the parent container
                                    >
                                        <PersistentGrid<MarketDataType>
                                            loading={loading}
                                            rowData={elecData}
                                            columnDefs={colDefs}
                                            groupDisplayType={'groupRows'}
                                            rowGroupPanelShow={"always"}
                                            defaultColDef={defaultColDef}
                                            autoSizeStrategy={{type: 'fitGridWidth'}}
                                            groupDefaultExpanded={1}
                                        />
                                    </div>
                                </Box>
                            </TabPanel>
                            <TabPanel h={"100%"}>
                                <Box w="100%" h="100%">
                                    <div
                                    className="ag-theme-quartz" // applying the Data Grid theme
                                    style={{ height: '100%', width: '100%' }} // the Data Grid will fill the size of the parent container
                                    >
                                        <PersistentGrid<MarketDataType>
                                            loading={loading}
                                            rowData={gasData}
                                            columnDefs={colDefs}
                                            groupDisplayType={'groupRows'}
                                            rowGroupPanelShow={"always"}
                                            defaultColDef={defaultColDef}
                                            autoSizeStrategy={{type: 'fitGridWidth'}}
                                            groupDefaultExpanded={1}
                                        />
                                    </div>
                                </Box>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </BaseTemplate.Body>
            </BaseTemplate>
      </PersistentGridProvider>
    );
}