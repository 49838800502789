import { useState, useEffect, useMemo, useRef, useContext } from "react";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
//import '../styles/ag-grid-theme-builder.css';
import { NotificationContextType, SupplyType } from "../types";
import { ColDef } from "ag-grid-enterprise";
import { Box, HStack } from "@chakra-ui/react";
import PersistentGrid from "../components/PersistentGrid/PersistentGrid";
import { useApi } from "../hooks/useApi";
import { NotificationContext } from "../components/NotificationPane";
import { CEDLinkRenderer, SupplyLinkRenderer } from "../components/CellRenderers";
import BaseTemplate from "../components/BaseTemplate";
import { PersistentGridProvider } from "../contexts/PersistentGridContext";
import PersistentGridResetButton from "../components/PersistentGrid/PersistentGridResetButton";
import PersistentGridViewSelector from "../components/PersistentGrid/PersistentGridViewSelector";
import { siteSummaryDefaults } from "../util/PresetViews";
import { caseInsensitiveComparator, dateValueFormatter, timezoneIndependantDateComparator } from "../util/AgGridFunctions";

function SiteSummary() {
    const [suppliesData, setSuppliesData] = useState<SupplyType[] | undefined>(undefined);
    const containerRef = useRef<HTMLDivElement>(null);
    const { ready, getSupplies } = useApi();
    const { pushNotification } = useContext<NotificationContextType | null>(NotificationContext)!;

    useEffect(() => {
        async function loadData() {
            try {
                const responseJson = await getSupplies();
                setSuppliesData(responseJson);
            } catch (err) {
                pushNotification("Could not load data.", "error")
            }
        }

        if (ready)
            loadData();
    }, [getSupplies, ready]);
    
    // Column Definitions: Defines the columns to be displayed.
    const [colDefs] = useState<ColDef<SupplyType>[]>([
        { field: "account_name", headerName: "Account Name", rowGroup: true, hide: true, sort: "asc", sortIndex: 0, minWidth: 200 },
        { field: "site_group", headerName: "Site Group", rowGroup: true, hide: true, sort: "asc", sortIndex: 1, minWidth: 200 },
        { field: "site_address", headerName: "Site Address", rowGroup: true, hide: true, sort: "asc", sortIndex: 2, minWidth: 200 },
        { field: "supply_type", headerName: "Supply Type", sort: "asc", sortIndex: 3, minWidth: 200 },
        { field: "supply_subtype", headerName: "Supply Subtype", sort: "asc", sortIndex: 4, minWidth: 200 },
        { field: "supply_verbose", headerName: "Supply Number", sort: "asc", sortIndex: 5, cellRenderer: SupplyLinkRenderer, minWidth: 300, cellStyle: {overflow: "visible"} },  
        { field: "supply_supplier", headerName: "Supplier", minWidth: 200 },
        { field: "contract_end_date", headerName: "Contract End Date", cellRenderer: CEDLinkRenderer, cellDataType: 'dateString', filter: 'agDateColumnFilter', minWidth: 200, filterParams: { comparator: timezoneIndependantDateComparator }, valueFormatter: dateValueFormatter },
        { field: "eb_currentconsumption", headerName: "Current Consumption", cellDataType: 'number', filter: 'agNumberColumnFilter', minWidth: 200 },
        { field: "supply_number", headerName: "Supply Number Short", hide: true, minWidth: 200 },
        { field: "ooc", headerName: "OOC", hide: true, minWidth: 200  }, 
        { field: "eb_meterserialnumber", headerName: "Meter Serial Number", hide: true, minWidth: 200  },
    ]);
    

    const defaultColDef: ColDef = useMemo(() => ({
        filter: 'agMultiColumnFilter',
        enableRowGroup: true,
        enableValue: true,
        comparator: caseInsensitiveComparator,
    }), []);
    
    return (
        <PersistentGridProvider gridKey={"site_summary"} views={siteSummaryDefaults}>
            <BaseTemplate fitToWindow={true}>
                <BaseTemplate.Header>
                    <HStack>
                        <PersistentGridResetButton />
                        <PersistentGridViewSelector />
                    </HStack>
                </BaseTemplate.Header>
                <BaseTemplate.Body>
                    <Box w="100%" h="100%" ref={containerRef}>
                        <div
                        className="ag-theme-quartz" // applying the Data Grid theme
                        style={{ height: '100%', width: '100%' }} // the Data Grid will fill the size of the parent container
                        >
                            <PersistentGrid<SupplyType>
                                rowData={suppliesData}
                                columnDefs={colDefs}
                                groupDisplayType={'groupRows'}
                                rowGroupPanelShow={"always"}
                                defaultColDef={defaultColDef}
                                autoSizeStrategy={{type: 'fitGridWidth'}}
                                suppressRowTransform
                                groupAllowUnbalanced
                            />
                        </div>
                    </Box>
                </BaseTemplate.Body>
            </BaseTemplate>
        </PersistentGridProvider>
    )
}
  
export default SiteSummary;
