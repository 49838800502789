import { useState, useEffect, useContext } from "react";
import { CalendarEventType, NotificationContextType, SupplyType } from "../types";
import EventCalendar from "./ContractRenewals/EventCalendar";
import CEDGrid from "./ContractRenewals/CEDGrid";
import moment from 'moment'
import { Button, Center, Grid, GridItem, Icon, Show } from "@chakra-ui/react";
import { useApi } from "../hooks/useApi";
import { NotificationContext } from "../components/NotificationPane";
import ConsultantContact from "./Home/Modules/ConsultantContact";
import BaseTemplate from "../components/BaseTemplate";
import { FaArrowsRotate } from "react-icons/fa6";

type ContractRenewalsProps = {
  ced?: Date | null;
}

function ContractRenewals({ced}: ContractRenewalsProps) {
  const { ready, getSupplies } = useApi();
  const { pushNotification } = useContext<NotificationContextType | null>(NotificationContext)!;
  const [suppliesData, setSuppliesData] = useState<SupplyType[] | undefined>(undefined);
  const [calendarEvents, setCalendarEvents] = useState<CalendarEventType[] | undefined>(undefined);
  const [selectedDates, setSelectedDates] = useState<Date[]>((ced) ? [ced] : []); // set selected dates to given ced if exists, otherwise empty

  useEffect(() => {
    if (ready)
      loadSupplies();
  }, [ready, getSupplies]);

  async function loadSupplies() {
    try {
      const responseJson = await getSupplies();

      // removes ced's in the past
      const filteredSupplies = responseJson.filter((supply) => {
        if (supply.rollingcontract) return true; // ooc's are shown in grid
        if (!supply.contract_end_date) return true; // no ced shown
        const supplyDate = new Date(supply.contract_end_date);
        return supplyDate >= new Date();
      });

      setSuppliesData(filteredSupplies);

      const events = []
      // !!! Object.groupBy throws typescript error but no issue with functionality - think its a version issue since a new function
      // @ts-expect-error !!!apparently still an experimental method - maybe worth using custom implementation?
      const groupedSupplies = Object.groupBy(filteredSupplies, ({contract_end_date} : {contract_end_date: string}) => contract_end_date);
      for (const date in groupedSupplies) {
        const dateMoment = moment(date, 'YYYY-MM-DD').toDate()
        events.push({
          start: dateMoment,
          end: dateMoment,
          title: `${groupedSupplies[date].length} end`,
        })
      }
      setCalendarEvents(events)

    } catch (err) {
      // !!! notification context error
      pushNotification("Could not load supplies.", "error");
    }
  }

  return (
    <BaseTemplate>
      <BaseTemplate.Body>
        <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(3, 1fr)' h='100%' gap={8} padding={2} margin={2}>
          <GridItem colSpan={{ base: 3, lg: 2 }}>
            <Center><EventCalendar calendarEvents={calendarEvents} selectedDates={selectedDates} setSelectedDates={setSelectedDates} /></Center>
            <Button m={2} onClick={() => setSelectedDates([])}><Icon as={FaArrowsRotate}></Icon></Button>
          </GridItem>
          <Show above='lg'>
            <GridItem>
                <ConsultantContact/>
            </GridItem>
          </Show>
          <GridItem colSpan={3}>
            <Center><i>Select a date or multiple dates to filter the below table.</i></Center>
            <Center><CEDGrid suppliesData={suppliesData} selectedDates={selectedDates} /></Center>
          </GridItem>
        </Grid>
      </BaseTemplate.Body>
    </BaseTemplate>
  )
}

export default ContractRenewals